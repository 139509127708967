import Link from "next/link"
import s from "../../../../styles/Home.module.scss"
import React, { useEffect, useState } from "react"
import Image from "next/image"
import MenuWallet from "../../WalletConnect/menuWallet"
import { AnimationControls, useAnimation, motion } from "framer-motion"
import HapeOrNot from "../../../../pages/hapeornot"
import { SocialLinks } from "./SocialLinks"
import Navbar from "../../LandingPage/NavBar/navbar"
import { useAtom } from "jotai"
import {
	deviceTypeAtom,
	useDeviceType,
} from "../../../../store/global/DeviceState"

export default function Sidebar({
	openAboutModal,
	isFilterSidebarOpen,
	filterBackgroundColor,
	sidebarFilter,
	isViewingMyHapes,
	isModalOpen,
	currentHapeTokenId,
	handleGetMyHapes,
	handleFilterCollapse,
	openLeaderboardModal,
	isHapeOrNot,
	isMarketplace,
	openCartModal,
	openInventoryModal,
	goToMarketplaceHomepage,
}) {
	const { deviceType, isMobile } = useDeviceType()

	if (!deviceType) {
		return null
	}

	return (
		<div>
			{!isMobile && (
				<div className={s.utility_bar}>
					<div className={s.utility_top}>
						<div className={s.menu}>
							<div
								className={s.menu_parent}
								onClick={() => openAboutModal(false)}
							>
								<div className={s.vertical_line}>
									| <div className={s.menu_text}>Menu</div> |
								</div>
							</div>
							<div className={s.wallet}>
								<MenuWallet />
							</div>
						</div>
						<div className={s.links}>
							<SocialLinks isInAbout={false} />
						</div>
					</div>
					<div className={s.utility_bottom}>
						<div
							className={s.hape_logo}
							onClick={() => {
								window.open("https://hape.io", "_blank")
							}}
						>
							<Image
								src={`/static/img/logos/hape_rebrand_logo_white.svg`}
								priority={true}
								width={400}
								height={100}
								quality={100}
								alt={"Collapse Filter"}
								layout={"intrinsic"}
							/>
						</div>
					</div>
				</div>
			)}
			<div>
				{isMobile && (
					<>
						<div
							className={s.filter_bar}
							style={{
								visibility: isFilterSidebarOpen ? "visible" : "hidden",
								height: "100%",
							}}
						>
							{sidebarFilter}
						</div>
					</>
				)}
			</div>
			{isMobile ? (
				<Navbar
					isMobile={isMobile}
					openAboutModal={openAboutModal}
					isFilterSidebarOpen={isFilterSidebarOpen}
					isMarketplace={isMarketplace}
					openCartModal={openCartModal}
					isHapeOrNot={isHapeOrNot}
					isViewingMyHapes={isViewingMyHapes}
					handleGetMyHapes={handleGetMyHapes}
					handleFilterCollapse={handleFilterCollapse}
					isModalOpen={isModalOpen}
				/>
			) : (
				<div
					className={`${isMarketplace ? s.filter_white_bar : s.filter_bar} ${
						isFilterSidebarOpen ? s.filter_bar_open : s.filter_bar_closed
					}`}
					style={{ backgroundColor: filterBackgroundColor }}
				>
					<div
						style={{
							visibility: isFilterSidebarOpen ? "visible" : "hidden",
							height: "100%",
						}}
					>
						{sidebarFilter}
					</div>
					<>
						{!isViewingMyHapes ? (
							<motion.div
								className={`${
									isHapeOrNot
										? s.hape_or_not_logo
										: isMarketplace
											? s.marketplace_index_logo
											: s.fashion_index_logo
								}`}
								style={{
									visibility: !isFilterSidebarOpen ? "visible" : "hidden",
								}}
							>
								{isMarketplace ? (
									<img
										onClick={goToMarketplaceHomepage}
										style={{
											cursor: "pointer",
										}}
										src={`/static/img/marketplace/logos/drops_sidebar_logo_2.svg`}
									/>
								) : !isHapeOrNot ? (
									<>Fashion Index</>
								) : (
									<>
										<img
											src={`/static/img/hapeornot/hape_or_not_utility_logo.svg`}
										/>
									</>
								)}
							</motion.div>
						) : (
							<>
								<div
									className={s.fashion_index_logo}
									style={{
										visibility: !isFilterSidebarOpen ? "visible" : "hidden",
									}}
								>
									My Hapes
								</div>
							</>
						)}
					</>

					{isModalOpen && (
						<motion.div
							className={s.sidebar_token_id}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
							}}
						>
							<span>#{currentHapeTokenId}</span>
							{!isMobile && (
								<span className={`${s.sidebar_hape_prime_text}`}>
									HAPE PRIME
								</span>
							)}
						</motion.div>
					)}
					{!isModalOpen && !isHapeOrNot && (
						<button
							className={`${s.bottom_icon} ${s.raise} ${
								!isViewingMyHapes ? s.is_unactivated : ""
							}`}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
							}}
							onClick={handleGetMyHapes}
						>
							<Image
								src={`/static/img/homepage/my_hapes.svg`}
								priority={true}
								width={22}
								height={22}
								quality={100}
								alt={"My HAPEs"}
							/>
						</button>
					)}
					{isMarketplace && (
						<>
							<button
								className={`${s.bottom_icon} ${s.raise}`}
								style={{
									visibility: !isFilterSidebarOpen ? "visible" : "hidden",
									paddingBottom: "4.5rem",
								}}
								onClick={openAboutModal}
							>
								<Image
									src={`/static/img/marketplace/question_mark_grey.svg`}
									priority={true}
									width={22}
									height={22}
									quality={100}
									alt={"Question Mark"}
								/>
							</button>
						</>
					)}
					{isMarketplace && (
						<button
							className={`${s.bottom_icon} ${s.raise}`}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
								paddingBottom: "2rem",
							}}
							onClick={() => openCartModal()}
						>
							<Image
								src={`/static/img/marketplace/add_to_cart.svg`}
								priority={true}
								width={22}
								height={22}
								quality={100}
								alt={"Cart_icon"}
							/>
						</button>
					)}
					{isMarketplace && (
						<button
							className={`${s.bottom_icon_menu}`}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
							}}
							onClick={() => openInventoryModal()}
						>
							<Image
								src={`/static/img/marketplace/menu.svg`}
								priority={true}
								width={22}
								height={22}
								quality={100}
								alt={"Leaderboard"}
							/>
						</button>
					)}

					{isHapeOrNot && (
						<button
							className={`${s.bottom_icon} ${s.raise}`}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
							}}
							onClick={openLeaderboardModal}
						>
							<Image
								src={`/static/img/hapeornot/leaderboard.svg`}
								priority={true}
								width={22}
								height={22}
								quality={100}
								alt={"Leaderboard"}
							/>
						</button>
					)}
					{isMobile && (
						<button
							className={`${s.bottom_icon} ${s.ham_menu}`}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden",
							}}
							onClick={() => openAboutModal(false)}
						>
							<Image
								src={
									isMarketplace
										? `/static/img/marketplace/black_hamburger_menu.svg`
										: `/static/img/homepage/hambuger_menu.svg`
								}
								priority={true}
								width={22}
								height={14}
								quality={100}
								alt={"Menu"}
								layout={"intrinsic"}
							/>
						</button>
					)}
					{isMarketplace ? (
						<></>
					) : (
						<>
							{!isModalOpen && (
								<button
									className={s.bottom_icon}
									style={{
										visibility: !isFilterSidebarOpen ? "visible" : "hidden",
									}}
									onClick={openAboutModal}
								>
									<Image
										src={`/static/img/homepage/question_mark.svg`}
										priority={true}
										width={22}
										height={22}
										quality={100}
										alt={"Question Mark"}
									/>
								</button>
							)}
							{isMobile && !isFilterSidebarOpen && (
								<div className={s.mobile_hape_logo_container}>
									<div
										className={s.mobile_hape_logo}
										style={{
											visibility: !isMarketplace ? "visible" : "hidden",
										}}
										onClick={() => {
											window.open("https://hape.io", "_blank")
										}}
									>
										<Image
											src={`/static/img/logos/hape_rebrand_logo_white.svg`}
											priority={true}
											width={400}
											height={100}
											quality={100}
											alt={"Collapse Filter"}
											layout={"intrinsic"}
										/>
									</div>
								</div>
							)}
						</>
					)}

					{!isHapeOrNot && (
						<div className={s.collapse_button} onClick={handleFilterCollapse}>
							{((!isViewingMyHapes && !isModalOpen) || isModalOpen) && (
								<>
									{isFilterSidebarOpen || isModalOpen ? (
										<Image
											src={`${
												isMarketplace
													? `/static/img/homepage/toggle_black_close_icon.svg`
													: `/static/img/homepage/filter_collapse_icon_close.svg`
											}`}
											priority={true}
											width={22}
											height={22}
											quality={100}
											alt={"Collapse Filter"}
										/>
									) : (
										<Image
											src={`${
												isMarketplace
													? `/static/img/homepage/toggle_black_icon.svg`
													: `/static/img/homepage/filter_collapse_icon_open.svg`
											}`}
											priority={true}
											width={22}
											height={22}
											quality={100}
											alt={"Collapse Filter"}
										/>
									)}
								</>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}
