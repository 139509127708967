import Image from "next/image"
import React, { useState, useEffect } from "react"
import s from "../../../../styles/Home.module.scss"

export const SocialLinks = ({ isInAbout }) => {
  const socials = !isInAbout
    ? [
        { name: "opensea", link: "https://opensea.io/collection/hapeprime" },
        { name: "discord", link: "https://discord.com/invite/hape" },
        { name: "twitter", link: "https://twitter.com/HAPEsocial" }
      ]
    : [
        { name: "twitter", link: "https://twitter.com/HAPEsocial" },
        { name: "opensea", link: "https://opensea.io/collection/hapeprime" },
        { name: "discord", link: "https://discord.com/invite/hape" }
      ]

  return (
    <>
      {socials.map(social => {
        return (
          <button
            key={social.name}
            className={`${s.link} ${isInAbout ? s.about_link : ""}`}
            onClick={() => {
              window.open(social.link, "_blank")
            }}
          >
            <Image
              src={`/static/img/socials/${social.name}.svg`}
              priority={true}
              width={50}
              height={50}
              quality={100}
              alt={social.name}
              layout={"intrinsic"}
            />
          </button>
        )
      })}
    </>
  )
}
