import Link from "next/link"
import React, { useEffect } from "react"
import s from "./navbar.module.scss"
import d from "../../../../styles/Home.module.scss"
import Image from "next/image"
import MenuWallet from "../../WalletConnect/menuWallet"
import { AnimationControls, useAnimation, motion } from "framer-motion"
import HapeOrNot from "../../../../pages/hapeornot"
import { SocialLinks } from "../../Layout/Sidebar/SocialLinks"

export default function Navbar({
	isMobile,
	openAboutModal,
	isFilterSidebarOpen,
	isMarketplace,
	openCartModal,
	isHapeOrNot,
	isViewingMyHapes,
	handleGetMyHapes,
	handleFilterCollapse,
	isModalOpen
}) {
	const isFashionIndex = () => {
		return !isMarketplace && !isHapeOrNot
	}
	return (
		<div>
			{!isMobile && (
				<div className={s.utility_bar}>
					<div className={s.utility_top}>
						<div className={s.menu}>
							<div
								className={s.menu_parent}
								onClick={() => openAboutModal(false)}
							>
								<div className={s.vertical_line}>
									| <div className={s.menu_text}>Menu</div> |
								</div>
							</div>
							<div className={s.wallet}>
								<MenuWallet isMarketplace={isMarketplace} />
							</div>
						</div>
						<div className={s.links}>
							<SocialLinks isInAbout={false} />
						</div>
					</div>
					<div className={s.utility_bottom}>
						<div
							className={s.hape_logo}
							onClick={() => {
								window.open("https://hape.io", "_blank")
							}}
						>
							<Image
								src={`/static/img/logos/hape_rebrand_logo_white.svg`}
								priority={true}
								width={400}
								height={100}
								quality={100}
								alt={"Collapse Filter"}
								layout={"intrinsic"}
							/>
						</div>
					</div>
				</div>
			)}

			<div
				className={`${s.top_filter_navbar} ${
					isFashionIndex() ? s.fashion_index_theme : ""
				}`}
			>
				<div className={s.flex_wrap}>
					<div className={s.logo_mobile}>
						<img
							className={s.mob_logo}
							src={`${
								!isFashionIndex()
									? `/static/img/marketplace/mob_logo`
									: `/static/img/navbar/hape_logo`
							}.svg`}
							alt="Logo_img"
						/>
					</div>
					<div className={s.right_logos}>
						{isMarketplace && (
							<div className={s.spacing} onClick={() => openAboutModal(true)}>
								<Image
									src={"/static/img/marketplace/question_mark_grey.svg"}
									priority={true}
									width={20}
									height={20}
									quality={100}
									alt={"Cart_icon"}
								/>
							</div>
						)}
						{isMarketplace && (
							<div className={s.spacing} onClick={() => openCartModal()}>
								<Image
									src={"/static/img/marketplace/add_to_cart.svg"}
									priority={true}
									width={22}
									height={22}
									quality={100}
									alt={"Cart_icon"}
								/>
							</div>
						)}
						{isFashionIndex() && (
							<div className={s.collapse_button} onClick={handleFilterCollapse}>
								{((!isViewingMyHapes && !isModalOpen) || isModalOpen) && (
									<>
										{isFilterSidebarOpen || isModalOpen ? (
											<img
												style={{ filter: "brightness(0) invert(1)" }}
												src={`${`/static/img/homepage/toggle_black_close_icon.svg`}`}
												width={10}
												height={22}
												alt={"Collapse Filter"}
											/>
										) : (
											<img
												style={{ filter: "brightness(0) invert(1)" }}
												src={`${`/static/img/homepage/toggle_black_icon.svg`}`}
												width={10}
												height={22}
												alt={"Collapse Filter"}
											/>
										)}
									</>
								)}
							</div>
						)}
						{isFashionIndex() && (
							<button
								className={`${s.bottom_icon} ${s.raise} ${
									!isViewingMyHapes ? d.is_unactivated : ""
								}`}
								style={{
									visibility: !isFilterSidebarOpen ? "visible" : "hidden",
									borderRadius: 5,
									backgroundColor: "transparent",
									display: "flex",
									padding: 5,
									border: 0
								}}
								onClick={handleGetMyHapes}
							>
								<Image
									src={`/static/img/navbar/my_hapes.svg`}
									priority={true}
									width={18}
									height={18}
									quality={100}
									alt={"My HAPEs"}
								/>
							</button>
						)}
						<div className={s.spacing}>
							<MenuWallet
								isMarketplace={isMarketplace}
								isFashionIndex={isFashionIndex()}
								isMobile={isMobile}
							/>
						</div>
						<div
							className={s.spacing}
							style={{
								visibility: !isFilterSidebarOpen ? "visible" : "hidden"
							}}
							onClick={() => {
								openAboutModal(false)
							}}
						>
							{!isFashionIndex() ? (
								<Image
									src={`/static/img/marketplace/black_hamburger_menu.svg`}
									priority={true}
									width={22}
									height={14}
									quality={100}
									alt={"Menu"}
									layout={"intrinsic"}
								/>
							) : (
								<Image
									src={`/static/img/navbar/menu.svg`}
									priority={true}
									width={22}
									height={14}
									quality={100}
									alt={"Menu"}
									layout={"intrinsic"}
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* <div
        className={`${isMarketplace ? s.filter_white_bar : s.filter_bar} ${
          isFilterSidebarOpen ? s.filter_bar_open : s.filter_bar_closed
        }`}
        style={{ backgroundColor: filterBackgroundColor }}
      >
        <div
          style={{
            visibility: isFilterSidebarOpen ? "visible" : "hidden",
            height: "100%"
          }}
        >
          {sidebarFilter}
        </div>
        <>
          {!isViewingMyHapes ? (
            <motion.div
              className={`${
                isHapeOrNot ? s.hape_or_not_logo : s.fashion_index_logo
              }`}
              style={{
                visibility: !isFilterSidebarOpen ? "visible" : "hidden"
              }}
            >
              {isMarketplace ? (
                <img
                  src={`/static/img/marketplace/marketplace_sidebar_logo.svg`}
                />
              ) : !isHapeOrNot ? (
                <>Fashion Index</>
              ) : (
                <>
                  <img
                    src={`/static/img/hapeornot/hape_or_not_utility_logo.svg`}
                  />
                </>
              )}
            </motion.div>
          ) : (
            <>
              <div
                className={s.fashion_index_logo}
                style={{
                  visibility: !isFilterSidebarOpen ? "visible" : "hidden"
                }}
              >
                My Hapes
              </div>
            </>
          )}
        </>

        {isModalOpen && (
          <motion.div
            className={s.sidebar_token_id}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden" }}
          >
            <span>#{currentHapeTokenId}</span>
            {!isMobile && (
              <span className={`${s.sidebar_hape_prime_text}`}>HAPE PRIME</span>
            )}
          </motion.div>
        )}
        {!isModalOpen && !isHapeOrNot && (
          <button
            className={`${s.bottom_icon} ${s.raise} ${
              !isViewingMyHapes ? s.is_unactivated : ""
            }`}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden" }}
            onClick={handleGetMyHapes}
          >
            <Image
              src={`/static/img/homepage/my_hapes.svg`}
              priority={true}
              width={22}
              height={22}
              quality={100}
              alt={"My HAPEs"}
            />
          </button>
        )}
        {isMarketplace && (
          <button
            className={`${s.bottom_icon} ${s.raise}`}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden", paddingBottom:'2rem' }}
            onClick={() => openCartModal()}
          >
            <Image
              src={`/static/img/marketplace/add_to_cart.svg`}
              priority={true}
              width={22}
              height={22}
              quality={100}
              alt={"Cart_icon"}
            />
          </button>
        )}
        {isMarketplace && (
          <button
            className={`${s.bottom_icon_menu}`}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden" }}
            onClick={() => openInventoryModal()}
          >
            <Image
              src={`/static/img/marketplace/menu.svg`}
              priority={true}
              width={22}
              height={22}
              quality={100}
              alt={"Leaderboard"}
            />
          </button>
        )}

        {isHapeOrNot && (
          <button
            className={`${s.bottom_icon} ${s.raise}`}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden" }}
            onClick={openLeaderboardModal}
          >
            <Image
              src={`/static/img/hapeornot/leaderboard.svg`}
              priority={true}
              width={22}
              height={22}
              quality={100}
              alt={"Leaderboard"}
            />
          </button>
        )}
        {isMobile && (
          <button
            className={`${s.bottom_icon} ${s.ham_menu}`}
            style={{ visibility: !isFilterSidebarOpen ? "visible" : "hidden" }}
            onClick={() => openAboutModal(false)}
          >
            <Image
              src={`/static/img/homepage/hambuger_menu.svg`}
              priority={true}
              width={22}
              height={14}
              quality={100}
              alt={"Menu"}
              layout={"intrinsic"}
            />
          </button>
        )}
        {isMarketplace ? (
          ""
        ) : (
          <>
            {!isModalOpen && (
              <button
                className={s.bottom_icon}
                style={{
                  visibility: !isFilterSidebarOpen ? "visible" : "hidden"
                }}
                onClick={openAboutModal}
              >
                <Image
                  src={`/static/img/homepage/question_mark.svg`}
                  priority={true}
                  width={22}
                  height={22}
                  quality={100}
                  alt={"Question Mark"}
                />
              </button>
            )}
            {isMobile && !isFilterSidebarOpen && (
              <div className={s.mobile_hape_logo_container}>
                <div
                  className={s.mobile_hape_logo}
				  style={{
					visibility: !isMarketplace ? "visible" : "hidden"
				  }}
                  onClick={() => {
                    window.open("https://hape.io", "_blank")
                  }}
                >
                  <Image
                    src={`/static/img/logos/hape_rebrand_logo_white.svg`}
                    priority={true}
                    width={400}
                    height={100}
                    quality={100}
                    alt={"Collapse Filter"}
                    layout={"intrinsic"}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {!isHapeOrNot && (
          <div className={s.collapse_button} onClick={handleFilterCollapse}>
            {((!isViewingMyHapes && !isModalOpen) || isModalOpen) && (
              <>
                {isFilterSidebarOpen || isModalOpen ? (
                  <Image
                    src={`${
                      isMarketplace
                        ? `/static/img/homepage/toggle_black_close_icon.svg`
                        : `/static/img/homepage/filter_collapse_icon_close.svg`
                    }`}
                    priority={true}
                    width={22}
                    height={22}
                    quality={100}
                    alt={"Collapse Filter"}
                  />
                ) : (
                  <Image
                    src={`${
                      isMarketplace
                        ? `/static/img/homepage/toggle_black_icon.svg`
                        : `/static/img/homepage/filter_collapse_icon_open.svg`
                    }`}
                    priority={true}
                    width={22}
                    height={22}
                    quality={100}
                    alt={"Collapse Filter"}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div> */}
		</div>
	)
}
