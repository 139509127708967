import Image from "next/image"
import { useContext, useEffect, useState } from "react"
import { WalletContext } from "../../contexts/WalletContext"

export default function MenuWallet(props: any) {
	const { isMarketplace, isMobile, isFashionIndex } = props
	const [connectClicked, setConnectClick] = useState(false)
	const { connect, disconnect, getFormattedAddress, web3Provider } =
		useContext(WalletContext)

	useEffect(() => {
		if (web3Provider && !connectClicked) setConnectClick(true)
		if (!connectClicked) return
	}, [web3Provider])

	async function onclickConnect() {
		setConnectClick(true)
		await connect()
	}

	return (
		<>
			{!web3Provider && (
				<>
					{!isMobile && (
						<Image
							onClick={onclickConnect}
							src={`/static/img/wallet/wallet_closed.svg`}
							priority={true}
							width={35}
							height={27}
							quality={100}
							alt={"Wallet"}
						/>
					)}
					{isMobile && (
						<>
							{!isFashionIndex ? (
								<Image
									onClick={onclickConnect}
									src={`/static/img/wallet/wallet_closed_black.svg`}
									priority={true}
									width={22}
									height={17}
									quality={100}
									alt={"Wallet"}
								/>
							) : (
								<Image
									onClick={onclickConnect}
									src={`/static/img/navbar/wallet_connect.svg`}
									priority={true}
									width={22}
									height={17}
									quality={100}
									alt={"Wallet"}
								/>
							)}
						</>
					)}
				</>
			)}

			{web3Provider && (
				<>
					{!isMobile && (
						<Image
							onClick={disconnect}
							src={`/static/img/wallet/wallet_closed.svg`}
							priority={true}
							width={35}
							height={27}
							quality={100}
							alt={"Wallet"}
						/>
					)}
					{isMobile && (
						<>
							{!isFashionIndex ? (
								<Image
									onClick={disconnect}
									src={`/static/img/wallet/wallet_closed_black.svg`}
									priority={true}
									width={22}
									height={17}
									quality={100}
									alt={"Wallet"}
								/>
							) : (
								<Image
									onClick={disconnect}
									src={`/static/img/navbar/wallet_connect.svg`}
									priority={true}
									width={22}
									height={17}
									quality={100}
									alt={"Wallet"}
								/>
							)}
						</>
					)}
					{!isMobile && <div>{getFormattedAddress()}</div>}
					{/* {isMobile && <div style={{ fontSize: '10px', color: '#8E8E8E'}}>{getFormattedAddress()}</div>} */}
				</>
			)}
		</>
	)
}
