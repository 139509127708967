import s from "../ModalCard/Modal.module.scss"
import a from "../ModalAbout/ModalAbout.module.scss"
import React, { useContext, useEffect, useState, useRef } from "react"
import { IoClose } from "react-icons/io5"
import { motion } from "framer-motion"
import { SocialLinks } from "../Layout/Sidebar/SocialLinks"
import Image from "next/image"
import Link from "next/link"
import { WalletContext } from "../../contexts/WalletContext"
import { SocialLinksMarketplace } from "../Layout/Sidebar/SocialLinksMarketplace"
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll"

const ModalAbout = ({
	openAboutModal,
	aboutModalControls,
	triggerOpen,
	triggerClose,
	isMobile,
	isAbout,
	isHapeOrNot,
	isInstructions,
	isMarketplace,
	openCartModal,
	openOrderModal
}) => {
	useEffect(() => {
		document.addEventListener("keydown", onKeyDown)
	}, [])

	/**
	 * Close the modal when the Escape key is pressed
	 * @param {object} event
	 */
	const onKeyDown = event => {
		if (event.keyCode === 27) {
			triggerClose()
		}
	}

	useEffect(() => {
		triggerOpen()
	}, [])

	const [isSmallScreen, setIsSmallScreen] = useState(false)
	useDisableBodyScroll(isSmallScreen)

	useEffect(() => {
		const handleResizeMobile = () => {
			if (window.screen.width <= 450) {
				setIsSmallScreen(true)
			} else {
				setIsSmallScreen(false)
			}
		}

		handleResizeMobile()
		window.addEventListener("resize", handleResizeMobile)
		return () => window.removeEventListener("resize", handleResizeMobile)
	}, [isSmallScreen])

	const { connect } = useContext(WalletContext)

	function goToShop() {
		triggerClose()
	}
	function goToMyOrders() {
		triggerClose()
		openOrderModal()
	}
	function goToCart() {
		triggerClose()
		openCartModal()
	}

	return (
		<div>
			<motion.div
				className={s.pane}
				animate={aboutModalControls}
				style={{ zIndex: 899 }}
			>
				<div className={`${s.modal__modal_content}`}>
					<div
						id="main_modal"
						className={`${a.modal__outer} ${
							isInstructions ? a.modal__instructions : ""
						} ${a.modal__main}`}
					>
						<div className={a.modal__inner_container}>
							{!isInstructions ? (
								<div
									className={
										isMarketplace ? a.modal__marketplace_body : a.modal__body
									}
								>
									<div
										className={
											isMarketplace
												? a.modal__marketplace_header
												: a.modal__header
										}
									>
										{isMarketplace
											? "Welcome to DROPS!"
											: !isHapeOrNot
											? "Fashion Index"
											: "Hape Or Not"}
									</div>
									<div className={a.modal__inner_body}>
										{isAbout ? (
											<>
												{!isHapeOrNot && !isMarketplace && (
													<>
														<div>Welcome to the new Hape Fashion Index.</div>
														<br />
														<div>
															Please consider this a live Beta, built to
															accommodate future functionality and to test new
															features. Everything here can change; many things
															will. In short, {`don’t`} get too attached to your
															ranking right now.
														</div>
														<br />
														<div>
															Your {`Hape’s`} fashion score is now a manageable
															number, derived from the items you choose to wear
															and their scarcity. You can control that number by
															attaching and detaching items {`–`} at the moment
															limited to badges and wristbands, but ultimately
															any piece of apparel. The value of those items can
															go up and down, based on how many other people
															choose to wear them (and other factors including
															trends, matching sets and more).
														</div>
														<br />
														<div>
															{`We’re`} moving to a place where your {`Hape’s`}{" "}
															score, and thus your position on the ranking, is
															in your control. Again, this is a Beta, but{" "}
															{`it’s`} also the foundation for many things to
															follow; the next phase will add a new layer and{" "}
															{`we’ll`} keep building from there.
														</div>
														<br />
														<div>Team Hape</div>
														<div
															style={{
																display: "flex",
																justifyContent: "center",
																alignContent: "center"
															}}
														>
															<div
																style={{ width: !isMobile ? "60%" : "100%" }}
															>
																<Image
																	className={`${s.hapebeast}`}
																	src={`/static/img/homepage/about/infographic.png`}
																	priority={true}
																	width={1003}
																	height={413}
																	quality={100}
																	alt={"HAPE LOGO"}
																/>
															</div>
														</div>
													</>
												)}{" "}
												{isHapeOrNot && (
													<>
														<div>Welcome to Hape or Not.</div>
														<br />
														<div>
															{`The Fashion Index is unique in the NFT space: it
															provides a style ranking for your Hape based on
															their clothing and current trends. But we know, in
															the real world, that isn’t how fashion works.
															Style isn’t algorithmic but personal, not
															scientific but subjective.`}
														</div>
														<br />
														<div>
															{`Hape or Not is the human part of the Fashion
															Index. It lets you play your part in the position
															of every Hape: each swipe has the potential to
															change the ranking of a Hape in real time. You can
															also see which Hapes are scoring highly in the
															community, with bonus points awarded for each
															week’s winners.`}
														</div>
														<br />
														<div>
															{`As ever, this is early work. We’ll adjust the
															mathematics as we learn more about the way people
															use the site (and the effect that has on the
															rankings) and you should expect features to appear
															(and disappear). But this is another important
															step on the road to our Fashionverse. We made the
															Hapes; each of you gets to make them your own; and
															all of you will determine what happens next.`}
														</div>
														<br />
														<div>Hapefluencers, do your thing.</div>
														<br />
														<div>Team Hape</div>
														{!isMobile && (
															<>
																<div className={a.shortcuts_header}>
																	Keyboard Shortcuts:
																</div>
																<div className={a.shortcuts}>
																	<div className={a.shortcut}>
																		<div className={a.shortcut_img}>
																			<img
																				src={`/static/img/hapeornot/shortcut_left.svg`}
																				width={37}
																				height={40}
																				alt="left"
																			/>
																		</div>
																		<div>Vote Left</div>
																	</div>
																	<div className={a.shortcut}>
																		<div className={a.shortcut_img}>
																			<img
																				src={`/static/img/hapeornot/shortcut_right.svg`}
																				width={37}
																				height={40}
																				alt="right"
																			/>
																		</div>
																		<div>Vote Right</div>
																	</div>
																	<div className={a.shortcut}>
																		<div className={a.shortcut_img}>
																			<img
																				src={`/static/img/hapeornot/shortcut_up.svg`}
																				width={37}
																				height={40}
																				alt="up"
																			/>
																		</div>
																		<div>See Traits</div>
																	</div>
																	<div className={a.shortcut}>
																		<div className={a.shortcut_img}>
																			<img
																				src={`/static/img/hapeornot/shortcut_right.svg`}
																				width={37}
																				height={40}
																				alt="left"
																			/>
																		</div>
																		<div>Next Matchup</div>
																	</div>
																</div>
															</>
														)}
													</>
												)}
												{isMarketplace && (
													<div style={{ textAlign: "center" }}>
														<div
															style={{
																fontSize: "1.5rem",
																fontWeight: "bolder"
															}}
														>
															We're making Web3 history, together.
														</div>
														<div>
															We’ve built something that has never been done
															before. So, there’s bound to be some bugs in these
															first few weeks. <br />
															<br /> Please be patient with us, and help report
															any issues you notice to our support team in
															Discord. With your help, we’ll make this
															experience perfect - and continue to build
															groundbreaking new experiences for the industry.
														</div>
													</div>
												)}
											</>
										) : (
											<div>
												<div
													className={a.modal__about_text}
													onClick={openAboutModal}
												>
													{isMarketplace
														? ""
														: !isHapeOrNot
														? "Fashion Index"
														: "Hape Or Not"}
												</div>

												{isMarketplace && isSmallScreen && (
													<div className={a.modal__marketplace_header}>
														<div
															onClick={goToShop}
															className={a.modal__shop_wrap}
														>
															<div className={a.modal__shop_text}>Shop</div>
															<div>
																<img
																	src={"/static/img/marketplace/arrow_left.svg"}
																	alt="Arrow"
																/>
															</div>
														</div>

														<div
															onClick={goToMyOrders}
															className={a.modal__shop_wrap}
														>
															<div className={a.modal__shop_text}>
																My Orders
															</div>
															<div>
																<img
																	src={"/static/img/marketplace/arrow_left.svg"}
																	alt="Arrow"
																/>
															</div>
														</div>

														<div
															onClick={goToCart}
															className={a.modal__shop_wrap}
														>
															<div className={a.modal__shop_text}>Cart</div>
															<div>
																<img
																	src={"/static/img/marketplace/arrow_left.svg"}
																	alt="Arrow"
																/>
															</div>
														</div>
													</div>
												)}

												<Link href="/" passHref>
													<div className={a.modal__nav_link}>Fashion Index</div>
												</Link>
												<Link href="/hapeornot" passHref>
													<div
														className={`${a.modal__nav_link} ${a.modal__bottom_nav_link}`}
													>
														Hape Or Not
													</div>
												</Link>

												{!isMarketplace && (
													<Link href="/drops" passHref>
														<div
															className={`${a.modal__nav_link} ${a.modal__bottom_nav_link} 
													// ${a.modal__marketplace}
													`}
														>
															Drops
															{/* <span className={a.modal__coming_soon}>
														Coming soon.
													</span> */}
														</div>
													</Link>
												)}

												<div
													className={a.modal__connect_wallet}
													onClick={connect}
												>
													Connect Wallet
												</div>
											</div>
										)}
									</div>

									<div className={isMobile ? "" : ""}>
										<div>
											{isMobile && (
												<div className={`${a.modal__close_container}`}>
													<button
														type="button"
														className={`${s.modal__close_button}`}
														data-bs-dismiss="modal"
														aria-label="Close"
														onClick={() => triggerClose()}
													>
														<IoClose size={20} color="#393939" />
													</button>
												</div>
											)}
										</div>
										<div className={a.modal__footer}>
											{isMobile && <SocialLinksMarketplace isInAbout={true} />}

											{!isMobile && (
												<div className={`${a.modal__close_container}`}>
													<button
														type="button"
														className={`${s.modal__close_button}`}
														data-bs-dismiss="modal"
														aria-label="Close"
														onClick={() => triggerClose()}
													>
														<IoClose />
													</button>
												</div>
											)}
										</div>
									</div>
								</div>
							) : (
								<div>
									{isMobile ? (
										<div className={a.instructions}>
											<img
												src={`/static/img/hapeornot/instructions_mobile.webp`}
												width={150}
												height={225}
												alt="Instructions"
											/>
											<div className={`${a.instructions_text}`}>
												<div>Swipe Up</div>
												<div> {"&"} Down</div>
												<div className={a.instructions_bottom}>To Vote</div>
											</div>
										</div>
									) : (
										<div className={a.instructions}>
											<img
												src={`/static/img/hapeornot/instructions_web.webp`}
												width={300}
												height={200}
												alt="Instructions"
											/>
											<div className={`${a.instructions_text}`}>
												<div>Drag Left</div>
												<div> or Right</div>
												<div className={a.instructions_bottom}>To Vote</div>
											</div>
										</div>
									)}
									<br />
									<br />
									<br />
									<br />
									<br />
									<div className={`${a.modal__close_container}`}>
										<button
											type="button"
											className={`${s.modal__proceed}`}
											data-bs-dismiss="modal"
											aria-label="Close"
											onClick={() => triggerClose()}
										>
											Continue
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	)
}

export default ModalAbout
