import { atom, useAtom } from "jotai"

export const deviceTypeAtom = atom("")

export const useDeviceType = () => {
	const [deviceType] = useAtom(deviceTypeAtom)

	const isMobile = deviceType === "Mobile"
	const isTablet = deviceType === "Tablet"
	const isDesktop = deviceType === "Desktop"

	return { deviceType, isMobile, isTablet, isDesktop }
}
